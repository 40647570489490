<template>
  <div class="loading">
    <img src="@/assets/icons/hotel-loading.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "TLoading",
};
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 1000;
  background-color: rgba($dark, 0.8);
  img {
    width: 48px;
    height: 48px;
    animation: zoomInOut 1s infinite;
  }
}
@keyframes zoomInOut {
  0%,
  100% {
    transform: scale(1); /* Start and end at original size */
  }
  50% {
    transform: scale(1.5); /* Midway, scale up by 1.5 times */
  }
}
</style>
