<template>
  <header>
    <div class="header-wrapper">
      <div class="image">
        <img
          v-if="hotelInfo && hotelInfo.site_logo"
          :src="hotelInfo.site_logo"
          alt=""
        />
      </div>

      <!-- <span class="brand-name">Grand Yazıcı</span> -->
      <CurrencySelect
        v-if="selectedCurrency && step === 1 && $route.name != 'order-success'"
      />
    </div>
  </header>
</template>

<script>
import CurrencySelect from "./CurrencySelect.vue";
export default {
  name: "HotelHeader",
  components: {
    CurrencySelect,
  },
};
</script>

<style lang="scss" scoped>
header {
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.03);

  height: $header-h;
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $header-h;
    width: $width;
    margin: 0 auto;
    @media only screen and (max-width: 768px) {
      width: $mobile-width;
    }
    margin: 0 auto;
    .image {
      img {
        max-height: 60px;
      }
    }
    .brand-name {
      font-size: 14px;
      font-weight: 500;
    }
  }
}
</style>
