<template>
  <Header v-if="$route.name !== '404' && $route.name !== 'order-success'" />
  <Loading v-if="appIsLoading" />
  <router-view />
</template>
<script>
import Header from "@/components/Header.vue";
import Loading from "@/components/Loading.vue";
import { useIndexStore } from "./store";
export default {
  components: {
    Header,
    Loading,
  },
  mounted() {
    const currency = this.$cookies.get("currency");
    if (currency) {
      useIndexStore().selectedCurrency = currency;
      // useIndexStore().filter.currency = currency.code;
    }
  },
  watch: {
    appIsFullHeight(value) {
      if (value) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    },
    appIsLoading(value) {
      if (value) {
        document.body.classList.add("app-loading");
      } else {
        document.body.classList.remove("app-loading");
      }
    },
  },
};
</script>
