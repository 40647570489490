import api from "@/utils/api";
import { defineStore } from "pinia";
import moment from "moment";
// import { hotelInfo, selectedProduct } from "@/utils/dummyData";
import { currencies } from "@/utils/dummyData";
import router from "@/router/index";
// import { app } from "@/main";
export const useIndexStore = defineStore("useIndexStore", {
  state: () => ({
    apiKey: null,
    products: [],
    settings: null,
    availableRooms: null,
    step: 1,
    currencies: currencies,
    selectedCurrency: currencies[0],
    appIsLoading: false,
    appIsFullHeight: false,
    selectedProduct: null,
    hotelInfo: null,
    searchResultText: null,
    orderChargeRequest: {
      order_id: "",
      installment: 1,
      secure_pay: true,
      forward_url: "",
      amount: null,
      card: {
        card_holder_name: "",
        card_number: "",
        expire_month: "",
        expire_year: "",
        cvc: "",
      },
    },
    orderRequest: {
      product_id: "",
      room_requests: [],
      channel: "widget",
      contact_info: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        phone_code: "90",
        consent: false,
      },
      invoice_info: {
        title: "",
        type: "individual",
        tax_number: "",
      },
      guests: [],
    },
    filter: {
      apiKey: null,
      checkin: moment(new Date()).format("YYYY-MM-DD"),
      checkout: moment(new Date().setDate(new Date().getDate() + 5)).format(
        "YYYY-MM-DD"
      ),
      // checkin: "2023-11-06",
      // checkout: "2023-11-12",
      pax: [2],
      // currency: currencies[0].code,
    },
  }),

  actions: {
    settings() {
      const urlParams = new URLSearchParams(window.location.search);
      const apiKey = urlParams.get("apiKey");
      return api.settings(apiKey).then((res) => {
        this.settings = res.data.data;
        return res;
      });
    },
    search(payload) {
      this.appIsLoading = true;
      return api
        .search(payload)
        .then(async (res) => {
          this.products = res.data.data.products;
          this.selectedProduct = res.data.data.products[0];
          this.hotelInfo = {
            ...res.data.data.hotel_info,
            ...res.data.data.search,
          };
          this.searchResultText = JSON.parse(
            JSON.stringify({ ...this.filter })
          );

          return res;
        })
        .finally(() => {
          this.appIsLoading = false;
        });
    },

    async checkRoomAvailabilities(products, hotel_code) {
      let currentDate = new Date();
      let fromDate = moment(currentDate).format("yyyy-MM-DD");
      currentDate.setMonth(currentDate.getMonth() + 12);
      let toDate = moment(currentDate).format("yyyy-MM-DD");
      const payload = {
        code: hotel_code,
        pax: 2,
        fromDate: fromDate,
        toDate: toDate,
      };
      const res = await api.roomAvailabilities(payload);
      if (res) {
        const rooms = res.data.rooms;
        if (rooms) {
          const keys = Object.keys(rooms);

          keys.forEach((key) => {
            products.forEach((product, index) => {
              if (`${product.room_id[0]}-${product.meal_type_text}` == key) {
                products[index] = {
                  ...product,
                  roomAvailability: rooms[key],
                };
              }
            });
          });
          const tempAvailableRooms = [];
          if (Object.keys(rooms).length) {
            for (const key in rooms) {
              const found = products.some((product) => {
                const productKey = `${product.room_id[0]}-${product.meal_type_text}`;
                return key === productKey;
              });

              if (!found) {
                tempAvailableRooms.push({ roomAvailability: rooms[key] });
              }
            }
          }
          this.appIsLoading = false;
          this.products = products;

          return tempAvailableRooms;
        }
      }
    },
    order() {
      this.appIsLoading = true;
      return api
        .order(this.orderRequest)
        .then((res) => {
          return res;
        })
        .finally(() => {
          this.appIsLoading = false;
        });
    },
    orderCharge(order_id, amount) {
      if (amount) {
        var price = amount;
      } else {
        this.selectedProduct.price;
      }
      this.appIsLoading = true;
      this.orderChargeRequest.amount = price;
      this.orderChargeRequest.card.card_number =
        this.orderChargeRequest.card.card_number.replace(/\s/g, "");
      return api
        .ordersCharge({
          ...this.orderChargeRequest,
          order_id: order_id,
          forward_url: "https://widget.callmax.com.tr/order-success",
        })
        .then((res) => {
          return res;
          // if (res.status === 201) {
          //   router.push({ name: "order-success" });
          // }
        })
        .catch(() => {
          // Handle request or response errors here
          // console.log(error);
        })
        .finally(() => {
          this.appIsLoading = false;
        });
    },
    installments(payload) {
      return api.installments(payload).then((res) => {
        return res.data.items;
      });
    },
    async getOrderWithHotel(orderId) {
      let response;
      await api
        .getOrderById(orderId)
        .then((res) => {
          response = res;
        })
        .catch(() => {
          router.push({ name: "404" });
        });
      const order = response.data;
      const hotelId = order.hotel.id;

      let res = await api.getHotelById(hotelId, "cover,images,themes");
      if (!res) {
        res = {
          cover: null,
          name: order.accomodation.hotel_name,
          detailURL: "",
        };
      }

      order.accomodation = {
        ...order.accomodation,
        ...res.data,
        logo_url: res.data?.settings?.logo_url,
      };
      return { ...order };
    },
  },
});
