import { mapState, mapWritableState } from "pinia";
import { useIndexStore } from "@/store/index";

export default {
  computed: {
    ...mapState(useIndexStore, [
      "products",
      "hotelInfo",
      "currencies",
      "settings",
      "availableRooms",
    ]),
    ...mapWritableState(useIndexStore, [
      "appIsLoading",
      "appIsFullHeight",
      "step",
      "selectedProduct",
      "filter",
      "searchResultText",
      "orderRequest",
      "apiKey",
      "orderChargeRequest",
      "selectedCurrency",
    ]),
  },
  methods: {
    scrollToTop() {
      // Determine the target scroll position (e.g., 0 for scrolling to the top)
      const targetScrollPosition = 0;
      // Calculate the duration of the scroll animation
      const duration = 200; // 500 milliseconds (adjust as needed)
      // Get the current scroll position
      const startScrollPosition = window.scrollY;
      // Calculate the change in scroll position
      const scrollChange = targetScrollPosition - startScrollPosition;
      // Define the easing function (e.g., easeInOutQuad)
      function easeInOutQuad(t) {
        return t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2;
      }
      // Initialize the animation start time
      const startTime = performance.now();

      // Define the scroll animation function
      function animateScroll(currentTime) {
        const elapsedTime = currentTime - startTime;
        if (elapsedTime < duration) {
          // Calculate the new scroll position based on the easing function
          window.scrollTo(
            0,
            startScrollPosition +
              scrollChange * easeInOutQuad(elapsedTime / duration)
          );
          // Request the next animation frame
          requestAnimationFrame(animateScroll);
        } else {
          // Ensure the final scroll position is set
          window.scrollTo(0, targetScrollPosition);
        }
      }

      // Start the animation by requesting the first frame
      requestAnimationFrame(animateScroll);
    },

    moneyFormat(value) {
      return value.toLocaleString("tr-TR", {
        style: "currency",
        currency: useIndexStore().selectedCurrency.code,
        // minimumFractionDigits: 0,
      });
    },
  },
};
