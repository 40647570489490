export const selectedProduct = {
  id: "768e63ff-698f-48cf-9f4e-cbc87e9c607e",
  code: "18f34a_14978870434313315_1_22-12_2_2_0_2_963740243_0_1-6-0_5-100_224_2.20231106_6_2_2_224_zenea47888c90bb4b97915bd938d26f61cf_169887249600",
  list_price: 6756,
  price: 6756,
  discounted_amount: 0,
  discount_rate: 0,
  meal_type: "RO",
  meal_type_text: "Sadece Oda",
  cancel_policy: null,
  rooms: [
    {
      pax: { adult_quantity: 2 },
      room_category: "Standard Room",
      room_description: "Yandan Deniz Manzaralı Standard Oda",
      nightly_prices: {
        "2023-11-06": "1126.00",
        "2023-11-07": "1126.00",
        "2023-11-08": "1126.00",
        "2023-11-09": "1126.00",
        "2023-11-10": "1126.00",
        "2023-11-11": "1126.00",
      },
    },
  ],
};

export const hotelInfo = {
  name: "Grand Yazıcı Hotels",
  hotel_code: "18f34a",
  facilities: [
    {
      id: "101c",
      facility_type_id: "19",
      name: "Araba Kiralama",
      slug: "araba-kiralama",
    },
    {
      id: "103b",
      facility_type_id: "2a",
      name: "Kuru Temizleme",
      slug: "kuru-temizleme",
    },
    { id: "108a", facility_type_id: "2c", name: "Sauna", slug: "sauna" },
    { id: "10a8", facility_type_id: "2c", name: "Hamam", slug: "hamam" },
    {
      id: "10b4",
      facility_type_id: "2d",
      name: "Su Sporları",
      slug: "su-sporlari",
    },
    {
      id: "10d6",
      facility_type_id: "2a",
      name: "Nöbetçi Doktor",
      slug: "nobetci-doktor",
    },
    { id: "111e", facility_type_id: "24", name: "Balayı", slug: "balayi" },
    {
      id: "1194",
      facility_type_id: "13",
      name: "A La Carte Restoran",
      slug: "a-la-carte-restoran",
    },
    { id: "1195", facility_type_id: "13", name: "Bar", slug: "bar" },
    {
      id: "11cc",
      facility_type_id: "16",
      name: "Evcil Hayvan Kabul Edilmez",
      slug: "evcil-hayvan-kabul-edilmez",
    },
    { id: "1269", facility_type_id: "25", name: "Telefon", slug: "telefon" },
    {
      id: "1357",
      facility_type_id: "2a",
      name: "Kablosuz Internet",
      slug: "kablosuz-internet",
    },
    {
      id: "136d",
      facility_type_id: "2c",
      name: "Şezlong Ve/Veya Şemsiye",
      slug: "sezlong-ve-veya-semsiye",
    },
  ],
  top_facilities: [
    {
      id: "1194",
      facility_type_id: "13",
      name: "A La Carte Restoran",
      slug: "a-la-carte-restoran",
    },
  ],
  id: "b36f6ac8-6f99-4dd5-bd8f-2272644cc9ca",
};

export const orderRequest = {
  product_id: "c67ed897-15e4-4a43-99fb-ada616e1dd14",
  room_requests: [],
  channel: "widget",
  contact_info: {
    firstname: "FURKAN",
    lastname: "BAYRAM",
    email: "sefikfurkanbayram@gmail.com",
    phone: "5465459634",
    phone_code: "90",
    consent: true,
  },
  guests: [
    {
      firstname: "FURKAN",
      lastname: "BAYRAM",
      type: "adult",
      gender: "m",
      is_tc: true,
      age: null,
      tc_number: "19417646930",
      passport_number: null,
      birth_date: "18-07-1996",
    },
    {
      firstname: "MERVE",
      lastname: "DENİZ",
      type: "adult",
      gender: "f",
      is_tc: true,
      age: null,
      tc_number: "19417646930",
      passport_number: null,
      birth_date: "10-10-1994",
    },
    {
      firstname: "DENİZ",
      lastname: "EMRE",
      type: "child",
      gender: "c",
      is_tc: true,
      age: 7,
      tc_number: "19417646930",
      passport_number: null,
      birth_date: "10-11-2016",
    },
  ],
  invoice_info: {
    title: "FURKAN BAYRAM",
    type: "individual",
    tax_number: "19417646930",

    address: "Test adresi",
    city: "İstanbul",
    tax_office: "İstanbul",
  },
};

export const orderChargeRequest = {
  order_id: "616cae78-fd10-4799-900f-e749787b6ef8",
  installment: 1,
  secure_pay: false,
  amount: 35190,
  forward_url:
    "https://www.tatil.com/checkout/success?orderId=74a1850f-829f-4c1f-a399-a9f23502eb0c",
  card: {
    card_holder_name: "SEFIK FURKAN BAYRAM",
    card_number: "4256690000000001",
    expire_month: "11",
    expire_year: "2024",
    cvc: "123",
  },
};

export const currencies = [
  {
    name: "Türk Lirası",
    code: "TRY",
    symbol: "₺",
  },
  {
    name: "Euro",
    code: "EUR",
    symbol: "€",
  },
  {
    name: "Amerikan Doları",
    code: "USD",
    symbol: "$",
  },
  {
    name: "İngiliz Sterlini",
    code: "GBP",
    symbol: "£",
  },
];
