import axios from "axios";
import { useToast } from "vue-toastification";
import VueCookies from "vue-cookies";
import { useIndexStore } from "@/store";

const toast = useToast();

const waAxios = axios.create({
  baseURL: "https://widget-api.callmax.com.tr/api/",
  // baseURL: "https://widget-api.hotelstore.dev/api/",
  timeout: 30000,
});
waAxios.interceptors.request.use((config) => {
  // const currency = VueCookies.get("currency");
  const storeCurrency = useIndexStore().selectedCurrency.code;
  config.headers["X-Currency"] = storeCurrency || "TRY";
  return config;
});

const caAxios = axios.create({
  baseURL: "https://api.callmax.com.tr/",
  // baseURL: "https://api-test.tatil.com/",
  timeout: 30000,
});
caAxios.interceptors.request.use((config) => {
  const currency = VueCookies.get("currency");
  config.headers["X-Currency"] = currency?.code || "TRY";
  return config;
});
caAxios.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    toast.error(error.response.data.error, {
      timeout: 7000,
    });
    return Promise.reject(error);
  }
);
const api = {
  settings(apiKey) {
    return waAxios.get(`/hotel/settings`, { params: { apiKey: apiKey } });
  },
  search(payload) {
    return waAxios.get(`/search${payload}`);
  },

  order(payload) {
    return caAxios.post("/v2/orders/", payload);
  },

  installments(payload) {
    return caAxios.get(`/v2/orders/installments`, { params: payload });
  },
  ordersCharge(payload) {
    return caAxios.post("/v2/orders/charge", payload);
  },
  roomAvailabilities(payload) {
    return caAxios.get("/hotels/availabilities/", { params: payload });
  },
  getOrderById(orderID) {
    return caAxios.get(`/v2/orders/${orderID}`);
  },
  getHotelById(code, includes = "facilities") {
    return caAxios.get(`/gocms/hotels/${code}?include=${includes}`);
  },
};
export default api;
