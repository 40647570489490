import * as Vue from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import globalMixins from "@/config/globalMixins";
import moment from "moment";
import Toast from "vue-toastification";
import VueCookies from "vue-cookies";

// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import { useIndexStore } from "./store";
import router from "./router";
import "@/assets/scss/main.scss";
import { Field, Form, configure, defineRule, ErrorMessage } from "vee-validate";
import * as AllRules from "@vee-validate/rules";
import { localize } from "@vee-validate/i18n";

import { reactive } from "vue";

const eventBus = reactive({
  emit(event, ...args) {
    this[event].forEach((callback) => callback(...args));
  },
  on(event, callback) {
    if (!this[event]) {
      this[event] = [];
    }
    this[event].push(callback);
  },
  off(event, callback) {
    if (!callback) {
      this[event] = [];
    } else {
      this[event] = this[event].filter((cb) => cb !== callback);
    }
  },
});

Object.keys(AllRules).forEach((rule) => {
  defineRule(rule, AllRules[rule]);
});

export const app = Vue.createApp(App);
const EventBusPlugin = {
  install(app) {
    app.config.globalProperties.$bus = eventBus;
    app.provide("eventBus", eventBus);
  },
};
app.config.globalProperties.$moment = moment;
app.use(EventBusPlugin);
moment.locale("tr");
const pinia = createPinia();
const options = {
  // You can set your default options here
};
app.use(router);
app.use(pinia);
app.use(Toast, options);
app.use(VueCookies, { expires: "7d" });
app.mixin(globalMixins);

router.afterEach((to) => {
  if (to.name == "home") {
    useIndexStore().settings();
  }
});

app.mount("#app");

app.component("VeeForm", Form);
app.component("VeeField", Field);
app.component("VeeErrorMessage", ErrorMessage);

defineRule("tckn", (value) => {
  // Field is empty, should pass
  value = String(value);

  if (!value.match(/^[0-9]{11}$/)) {
    return false;
  }

  const n = parseInt(value.substr(0, 1), 10);
  const i = parseInt(value.substr(1, 1), 10);
  const o = parseInt(value.substr(2, 1), 10);
  const r = parseInt(value.substr(3, 1), 10);
  const a = parseInt(value.substr(4, 1), 10);
  const s = parseInt(value.substr(5, 1), 10);
  const l = parseInt(value.substr(6, 1), 10);
  const c = parseInt(value.substr(7, 1), 10);
  const u = parseInt(value.substr(8, 1), 10);
  const d = parseInt(value.substr(9, 1), 10);
  const p = parseInt(value.substr(10, 1), 10);
  if ((n + o + a + l + u + i + r + s + c + d) % 10 !== p) {
    return false;
  }
  if ((7 * (n + o + a + l + u) + 9 * (i + r + s + c)) % 10 !== d) {
    return false;
  }
  if ((8 * (n + o + a + l + u)) % 10 !== p) {
    return false;
  }

  return true;
});

defineRule("name", (value) => {
  if (!value || !value.length) {
    return true;
  }
  if (!/^[A-Za-z\sçÇşŞğĞıİöÖüÜ]+$/.test(value)) {
    return "Sadece harf giriniz.";
  }

  return true;
});
defineRule("phone", (value) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }
  if (!/^[1-9]\d{9}$/.test(value)) {
    return "Geçerli bir Telefon Numarası Giriniz";
  }

  return true;
});
defineRule("age", (value, age) => {
  const checkinDate = new Date(useIndexStore().filter.checkin);
  const birthYear = checkinDate.getFullYear() - age;
  const userBirthDate = moment(value, "DD/MM/YYYY").toDate();
  const birthDateStart = new Date(
    birthYear,
    checkinDate.getMonth(),
    checkinDate.getDate()
  );
  const birthDateEnd = new Date(
    birthYear + 1,
    checkinDate.getMonth(),
    checkinDate.getDate() - 1
  );

  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  const formattedStartDate = birthDateStart.toLocaleDateString(
    "tr-TR",
    options
  );
  const formattedEndDate = birthDateEnd.toLocaleDateString("tr-TR", options);
  if (userBirthDate < birthDateStart || userBirthDate > birthDateEnd) {
    return `Doğum tarihi ${formattedStartDate} - ${formattedEndDate} arasında olmalı.`;
  }
  return true;
});
defineRule("birthDate", (value) => {
  if (!value || !value.length) {
    return true;
  }
  var parts = value.split(".");
  var selectedDateObj = new Date(parts[2], parts[1] - 1, parts[0]);
  var today = new Date();
  if (selectedDateObj <= today && selectedDateObj.getFullYear() > 1900) {
    return true;
  } else {
    return "Geçerli Bir Doğum Tarihi Giriniz";
  }
});

configure({
  validateOnInput: true,
  generateMessage: localize("tr", {
    messages: {
      required: "Bu alan zorunludur.",
      email: "Geçerli bir mail adresi giriniz.",
      regex: "Geçerli bir tarih giriniz.",
      digits: "Geçerli bir giriş yapınız.",
      numeric: "Sadece rakam giriniz.",
      alpha: "Sadece harf giriniz.",
      tckn: "Geçerli bir giriş yapınız.",
      min: "Geçerli bir giriş yapınız.",
      max: "Geçerli bir giriş yapınız.",
    },
  }),
});
