<template>
  <div class="currency-select" @click="closeSelect()">
    <div class="select-area" @click.stop="openSelect()">
      <span>{{ selectedCurrency.code + " " + selectedCurrency.symbol }}</span>
      <img src="@/assets/icons/down-arrow.png" alt="" />
    </div>
    <div class="select-items-area" v-if="showItems">
      <div class="select-items">
        <span class="title">Kullanmak istediğiniz para birimini seçiniz</span>
        <ul>
          <li
            @click="changeCurrency(currency)"
            v-for="currency in currencies"
            :key="currency.code"
            :class="{ active: selectedCurrency.code === currency.code }"
          >
            <div class="top">
              <span class="name">{{ currency.name }}</span>
            </div>
            <div class="bottom">
              <span class="code"> {{ currency.code }}</span>
              <span class="symbol"> - {{ currency.symbol }}</span>
            </div>
          </li>
        </ul>
        <div class="close-button" @click="closeSelect()">
          <img src="@/assets/icons/close.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useIndexStore } from "@/store";
export default {
  name: "CurrencySelect",
  data() {
    return {
      showItems: false,
    };
  },
  computed: {},
  methods: {
    openSelect() {
      this.showItems = true;
      this.appIsFullHeight = true;
    },
    closeSelect() {
      this.showItems = false;
      this.appIsFullHeight = false;
    },
    changeCurrency(currency) {
      // this.filter.currency = currency.code;
      this.showItems = false;
      this.selectedCurrency = currency;
      this.$cookies.set("currency", currency);
      const searchParam = "?" + new URLSearchParams(this.filter).toString();
      useIndexStore().search(searchParam);

      this.appIsFullHeight = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.currency-select {
  .select-area {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    width: fit-content;
    border-radius: 8px;
    padding: 12px 24px;
    img {
      width: 14px;
      height: 14px;
      margin-left: 4px;
    }
    &:hover {
      background-color: $light-2;
    }
  }
  .select-items-area {
    position: absolute;
    z-index: 125;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($dark, 0.4);
    border: 1px solid $light;
    display: flex;
    align-items: center;
    justify-content: center;
    .select-items {
      background-color: $white;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      border-radius: 8px;
      padding: 24px 38px;
      width: 30vw;
      position: relative;
      @media only screen and (max-width: 768px) {
        width: 80vw;
        padding: 24px 16px;
      }

      .title {
        font-weight: 500;
        color: $dark;
        display: block;
        margin-bottom: 32px;
        margin-top: 12px;
      }
      ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 12px;
        li {
          padding: 4px 0;
          font-weight: 500;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          padding: 14px 10px;
          border-radius: 8px;

          .top {
            .name {
              color: $dark;
              font-size: 14px;
              font-weight: 500;
            }
          }
          .bottom {
            span {
              color: $grey;
              font-size: 12px;
            }
          }
          &.active {
            border: 1px solid $grey;
          }
          &:hover {
            background-color: $light-2;
          }
        }
      }
      .close-button {
        position: absolute;
        top: -36px;
        right: 0;
        cursor: pointer;
        img {
          width: 28px;
          height: 28px;
        }
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}
</style>
